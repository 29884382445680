import React, {useState, useEffect} from 'react';
import {
    TextField,
    Button,
    Container,
    Paper,
    Typography,
    Switch,
    FormControlLabel,
    CircularProgress,
    Snackbar,
    Alert,
} from '@mui/material';
import axiosInstance from "../AxiosInstance";
import Box from "@mui/material/Box";

function ThirdPartyIntegrationsPage() {
    const [organisationIntegrationId, setOrganisationIntegrationId] = useState(null); // Optional ID
    const [apiId, setApiId] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [integrationEnabled, setIntegrationEnabled] = useState(false); // State for the toggle switch
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(''); // Error state
    const [success, setSuccess] = useState(false); // Success notification state

    useEffect(() => {
        // Fetch organisation integration data when the page loads
        const fetchIntegration = async () => {
            try {
                const response = await axiosInstance.get('/settings');
                const data = response.data;

                // Populate fields with existing integration data
                setApiId(data.apiId || ''); // Set API ID if available
                setApiKey(data.apiKey || ''); // Set API Key if available
                setIntegrationEnabled(data.isEnabled || false); // Set toggle state
            } catch (err) {
                if (err.response?.status === 404) {
                    // No integration found, set fields to default
                    setOrganisationIntegrationId(null);
                    setApiId('');
                    setApiKey('');
                    setIntegrationEnabled(false);
                } else {
                    // Other errors
                    setError(
                        err.response?.data?.message ||
                        'Failed to fetch organisation integration. Please try again later.'
                    );
                }
            } finally {
                setLoading(false); // Stop loading spinner
            }
        };

        fetchIntegration();
    }, []);

    const handleToggle = (event) => {
        setIntegrationEnabled(event.target.checked);
    };

    const handleSave = async () => {
        try {
            const payload = {
                organisationIntegrationId, // Include the ID if it exists
                apiId: apiId || null, // Send null if API ID is empty
                apiKey: apiKey || null, // Send null if API Key is empty
                isEnabled: integrationEnabled,
            };

            await axiosInstance.put('/settings', payload); // Save updated integration details
            setSuccess(true); // Show success notification
        } catch (err) {
            setError(
                err.response?.data?.message ||
                'Failed to save organisation integration. Please try again later.'
            );
        }
    };

    return (
        <Box className="component-container">
            <Container component="main" maxWidth="sm">
                <Paper
                    elevation={3}
                    style={{
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        3rd Party Integrations
                    </Typography>

                    {loading ? (
                        <CircularProgress style={{marginTop: '20px'}}/> // Show loading spinner
                    ) : (
                        <>
                            {/* Integration Enable/Disable Switch */}
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={integrationEnabled}
                                        onChange={handleToggle}
                                        color="primary"
                                    />
                                }
                                label="Enable Unleashed Integration"
                                style={{marginTop: '16px'}}
                            />

                            {/* Form */}
                            <form style={{width: '100%', marginTop: '16px'}}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="API ID"
                                    value={apiId}
                                    onChange={(e) => setApiId(e.target.value)}
                                    disabled={!integrationEnabled} // Disable when integration is off
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="API Key"
                                    value={apiKey}
                                    onChange={(e) => setApiKey(e.target.value)}
                                    disabled={!integrationEnabled} // Disable when integration is off
                                />
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{marginTop: '24px'}}
                                    onClick={handleSave}
                                >
                                    Save
                                </Button>
                            </form>
                        </>
                    )}
                </Paper>

                {/* Snackbar for Error Notification */}
                <Snackbar
                    open={!!error}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    autoHideDuration={6000}
                    onClose={() => setError('')}
                >
                    <Alert onClose={() => setError('')} severity="error" sx={{width: '100%'}}>
                        {error}
                    </Alert>
                </Snackbar>

                {/* Snackbar for Success Notification */}
                <Snackbar
                    open={success}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    autoHideDuration={6000}
                    onClose={() => setSuccess(false)}
                >
                    <Alert onClose={() => setSuccess(false)} severity="success" sx={{width: '100%'}}>
                        Changes saved successfully!
                    </Alert>
                </Snackbar>
            </Container>
        </Box>
    );
}

export default ThirdPartyIntegrationsPage;
