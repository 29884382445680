import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableContainer from "@mui/material/TableContainer";

const OutputsAccountTable = ({ data, startMonth }) => {
    const baseMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const getOrderedMonthNames = (startMonth) => {
        const monthNames = [...baseMonthNames];
        const rotationIndex = (startMonth - 1) % 12;

        const rotatedMonths = [...monthNames.slice(rotationIndex), ...monthNames.slice(0, rotationIndex)];

        return [...rotatedMonths, 'FY'];
    };

    const monthNames = getOrderedMonthNames(startMonth);

    const addCommas = (value) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const formatCurrency = (value) => {
        let formattedValue = Math.round(value * 100) / 100;
        formattedValue = formattedValue % 1 === 0 ? formattedValue.toString() : formattedValue.toFixed(2);
        return `£${addCommas(formattedValue)}`;
    };

    const formatPercentage = (value) => {
        let formattedValue = Math.round(value * 100) / 100;
        formattedValue = formattedValue % 1 === 0 ? formattedValue.toString() : formattedValue.toFixed(2);
        return `${addCommas(formattedValue)}%`;
    };

    const formatValue = (key, value) => {
        if (value === undefined) return 'N/A';

        if (key === 'grossProfitPercent') {
            return formatPercentage(value);
        } else if (['grossRevenue', 'triggers', 'fixedSpend', 'netRevenue', 'cogs', 'logs', 'grossProfit'].includes(key)) {
            return formatCurrency(value);
        } else {
            return addCommas(value.toLocaleString());
        }
    };

    const renderRows = (data) => {
        return Object.keys(data).map((key) => (
            <TableRow key={key} sx={{ height: 40 }}>
                <TableCell component="th" scope="row" sx={{ height: 40, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {key.replace(/([A-Z])/g, ' $1').trim().replace(/^./, str => str.toUpperCase())}
                </TableCell>
                {data[key].map((value, index) => (
                    <TableCell
                        key={index}
                        align="right"
                        sx={{
                            height: 40,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            ...(index === monthNames.length - 1 && { fontWeight: 'bold' }) // FY is bold
                        }}
                    >
                        {formatValue(key, value)}
                    </TableCell>
                ))}
            </TableRow>
        ));
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="account summary table">
                <TableHead>
                    <TableRow sx={{ height: 40 }}>
                        <TableCell sx={{ height: 40 }}></TableCell>
                        {monthNames.map((month, index) => (
                            <TableCell
                                key={index}
                                align="right"
                                sx={{
                                    height: 40,
                                    fontWeight: 'bold',
                                    ...(index === monthNames.length - 1 && { fontWeight: 'bold' }) // FY is bold
                                }}
                            >
                                {month.toUpperCase()}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data ? renderRows(data) : (
                        <TableRow sx={{ height: 40 }}>
                            <TableCell colSpan={monthNames.length + 1} sx={{ height: 40 }}>Loading...</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default OutputsAccountTable;
