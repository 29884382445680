import React, { useState, useEffect, useMemo } from 'react';
import OutputsAccountTable from './OutputsAccountTable';
import ForecastDropdown from "./ForecastDropdown";
import ForecastPeriodDropdown from "./ForecastPeriodDropdown";
import Box from "@mui/material/Box";
import CustomerDropdown from "./CustomerDropdown";
import ForecastProductDropdown from "./ForecastProductDropdown";
import axiosInstance from "../AxiosInstance";
import IconButton from "@mui/material/IconButton";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import {Skeleton} from "@mui/material";
import {downloadCsv} from "../Utilities/csvUtility";

const ForecastOutputsAccount = () => {
    const [forecasts, setForecasts] = useState([]);
    const [selectedForecastId, setSelectedForecastId] = useState(null);
    const [selectedForecastPeriodId, setSelectedForecastPeriodId] = useState(null);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [selectedChannelId, setSelectedChannelId] = useState(null);
    const [startPeriod, setStartPeriod] = useState(1);
    const [summaryData, setSummaryData] = useState(null);
    const [error, setError] = useState(null);
    const [loadingCsv, setLoadingCsv] = useState(false);
    const [loadingForecasts, setLoadingForecasts] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);

    const getToken = () => localStorage.getItem("token");

    useEffect(() => {
        const storedForecast = JSON.parse(localStorage.getItem('selectedForecast'));
        const storedForecastPeriodId = localStorage.getItem('selectedForecastPeriodId');

        if (storedForecast?.forecastId) {
            setSelectedForecastId(storedForecast.forecastId);
            setStartPeriod(storedForecast.startPeriod);
        }

        if (storedForecastPeriodId && storedForecastPeriodId !== "null") {
            setSelectedForecastPeriodId(parseInt(storedForecastPeriodId, 10));
        } else {
            setSelectedForecastPeriodId(null);
        }

        fetchForecastsAndPeriods();
    }, []);

    useEffect(() => {
        if (selectedForecastId && selectedForecastPeriodId !== null) {
            const fetchAccountSummary = async () => {
                setLoadingTable(true);
                try {
                    const params = {
                        forecastPeriodId: selectedForecastPeriodId,
                        customerId: selectedCustomerId,
                        channelId: selectedChannelId,
                        productId: selectedProductId
                    };
                    
                    const response = await axiosInstance.get(`/forecasts/${selectedForecastId}/outputs-account`, { params });
                    setSummaryData(response.data);
                    setError(null);
                } catch (error) {
                    console.error("Failed to fetch account summary:", error);
                    setSummaryData(null);
                    if (error.response && error.response.status === 404) {
                        setError("Account summary not found.");
                    } else {
                        setError("An unexpected error occurred.");
                    }
                } finally {
                    setLoadingTable(false);
                }
            };

            fetchAccountSummary();
        }
    }, [selectedForecastId, selectedForecastPeriodId, selectedCustomerId, selectedChannelId, selectedProductId]);
    
    const fetchForecastsAndPeriods = async () => {
        try {
            setLoadingForecasts(true);
            const token = getToken();
            const response = await axiosInstance.get("/forecasts", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setForecasts(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingForecasts(false);
        }
    };

    const handleForecastSelected = (id, period) => {
        localStorage.setItem('selectedForecast', JSON.stringify({ forecastId: id, startPeriod: period }));
        setSelectedForecastId(id);
        setStartPeriod(period);

        const storedForecastPeriodId = localStorage.getItem("selectedForecastPeriodId");
        
        if (storedForecastPeriodId) {
            const forecast = forecasts.find(f => f.forecastId === id);
            const validPeriod = forecast?.forecastPeriods.some(fp => fp.forecastPeriodId === parseInt(storedForecastPeriodId, 10));
            if (validPeriod) {
                setSelectedForecastPeriodId(storedForecastPeriodId);
            } else {
                setSelectedForecastPeriodId(null);
            }
        } else {
            setSelectedForecastPeriodId(null);
        }
    };

    const handleCustomerSelected = (selection) => {
        if (selection.type === 'channel') {
            setSelectedChannelId(selection.id);
            setSelectedCustomerId(null);
        } else {
            setSelectedCustomerId(selection.id);
            setSelectedChannelId(null);
        }
    };

    const handleForecastPeriodSelected = (forecastPeriodId) => {
        localStorage.setItem("selectedForecastPeriodId", forecastPeriodId);
        setSelectedForecastPeriodId(forecastPeriodId);
    };

    const handleProductSelected = ({ productId }) => {
        setSelectedProductId(productId);
    };

    const aggregateData = (data) => {
        if (!data) {
            return {};
        }

        const keys = ['baseUnits', 'promoUnits', 'grossRevenue', 'triggers', 'netRevenue', 'fixedSpend', 'cogs', 'logs', 'grossProfit', 'grossProfitPercent'];
        const aggregatedData = {};

        keys.forEach(key => {
            if (key !== 'grossProfitPercent') {
                let monthlyTotals = data[key].slice(0, 12);
                let fyTotal = monthlyTotals.reduce((acc, value) => acc + value, 0);
                aggregatedData[key] = [...monthlyTotals, fyTotal];
            }
        });

        const grossProfitTotals = data['grossProfit'].slice(0, 12);
        const grossRevenueTotals = data['grossRevenue'].slice(0, 12);

        const fyGrossProfit = grossProfitTotals.reduce((acc, value) => acc + value, 0);
        const fyGrossRevenue = grossRevenueTotals.reduce((acc, value) => acc + value, 0);
        const fyGrossProfitPercent = fyGrossRevenue === 0 ? 0 : (fyGrossProfit / fyGrossRevenue) * 100;

        aggregatedData['grossProfitPercent'] = grossProfitTotals.map((profit, index) => {
            const revenue = grossRevenueTotals[index];
            return revenue === 0 ? 0 : (profit / revenue) * 100;
        });

        aggregatedData['grossProfitPercent'].push(fyGrossProfitPercent);

        return aggregatedData;
    };

    const aggregatedData = useMemo(() => {
        if (summaryData) {
            return aggregateData(summaryData);
        }
        return {};
    }, [summaryData]);

    const startMonth = summaryData?.startMonth || 1;

    const handleDownloadCsv = async () => {
        try {
            const params = {
                forecastPeriodId: selectedForecastPeriodId,
                channelId: selectedChannelId,
                customerId: selectedCustomerId,
                productId: selectedProductId
            };
            const fileName = `OutputsAccount_${selectedForecastId}_${selectedForecastPeriodId}.csv`;

            await downloadCsv(`/forecasts/${selectedForecastId}/outputs-account-csv`, params, fileName, setLoadingCsv, setError);
        } catch (error) {
            console.error("Unexpected error during CSV download:", error);
        }
    };

    const selectedForecast = forecasts.find((f) => f.forecastId === selectedForecastId);

    return (
        <Box className="component-container">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, pb: 2 }}>
                {loadingForecasts ? (
                    <Skeleton variant="rectangular" width={200} height={40} />
                ) : (
                <ForecastDropdown
                    forecasts={forecasts}
                    selectedForecastId={selectedForecastId}
                    onForecastSelected={handleForecastSelected}
                />
                )}
                {loadingForecasts ? (
                    <Skeleton variant="rectangular" width={200} height={40} />
                ) : (
                <ForecastPeriodDropdown
                    forecastPeriods={selectedForecast ? selectedForecast.forecastPeriods : []}
                    selectedForecastPeriodId={selectedForecastPeriodId}
                    onForecastPeriodSelected={handleForecastPeriodSelected}
                />
                    )}
                {loadingForecasts ? (
                    <Skeleton variant="rectangular" width={200} height={40} />
                ) : (
                <CustomerDropdown onCustomerSelected={handleCustomerSelected} channelSelectable={true} />
                    )}
                {loadingForecasts ? (
                    <Skeleton variant="rectangular" width={200} height={40} />
                ) : (
                <ForecastProductDropdown
                    forecastId={selectedForecastId}
                    customerId={selectedCustomerId}
                    forecastPeriodId={selectedForecastPeriodId}
                    onProductSelected={handleProductSelected}
                />
                    )}
                {loadingForecasts ? (
                    <Skeleton variant="circular" width={32} height={32} />
                ) : (
                <Tooltip title="Download CSV">
                    <span>
                        <IconButton onClick={handleDownloadCsv} aria-label="download csv" disabled={loadingCsv}>
                            {loadingCsv ? <CircularProgress size={24} /> : <FileDownloadIcon />}
                        </IconButton>
                    </span>
                </Tooltip>
                    )}
            </Box>
            {error ? (
                <div>{error}</div>
            ) : loadingTable ? (
                <Skeleton variant="rectangular" width="100%" height={400} />
            ) : (
                <Box>
                    {selectedForecastId && selectedForecastPeriodId !== null && selectedForecastPeriodId > 0 && (
                        <OutputsAccountTable data={aggregatedData} startMonth={startMonth} />
                    )}
                </Box>
            )}
        </Box>
    );
};

export default ForecastOutputsAccount;
