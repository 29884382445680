import axiosInstance from "../AxiosInstance";

export const downloadCsv = async (url, params, fileName, setLoading, setError) => {
    setLoading(true);

    try {
        const response = await axiosInstance.get(url, {
            params,
            responseType: 'blob',
        });

        const blob = new Blob([response.data]);
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error("Failed to download CSV:", error);
        setError?.("Failed to download CSV. Please try again later.");
    } finally {
        setLoading(false);
    }
};
