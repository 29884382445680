import React from 'react';
import { Link } from 'react-router-dom';
import Box from "@mui/material/Box";

const AdminPage = () => {
    return (
        <Box className="component-container">
            <h2>Admin Dashboard</h2>
            <Link to="/register">Register a new user</Link>
        </Box>
    );
};

export default AdminPage;
