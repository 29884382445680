import React, { useMemo } from "react";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

const OutputsDemandTable = ({ summaryData }) => {
    // UseMemo to create columns, column grouping, and rows
    const { columns, columnGroupingModel, rows } = useMemo(() => {
        if (!summaryData || !summaryData.periodicDemands || summaryData.periodicDemands.length === 0) {
            return {
                columns: [],
                columnGroupingModel: [],
                rows: [],
            };
        }

        const weeks = Object.keys(summaryData.weekToMonthMap);

        // Group weeks by `WeekMonth`
        const monthMap = weeks.reduce((acc, week) => {
            const month = summaryData.weekToMonthMap[week]; // Get the `WeekMonth` value
            if (!acc[month]) acc[month] = [];
            acc[month].push(week);
            return acc;
        }, {});

        // Generate columns for weeks
        const weekColumns = weeks.map((week) => ({
            field: week,
            headerName: week,
            type: "number",
            width: 100,
            valueGetter: (value, row) =>
                row && row.units && row.units[week]?.totalUnits || 0, // Access `totalUnits`
        }));

        // Grouping model for column headers
        const columnGroupingModel = Object.entries(monthMap).map(([month, weeks]) => ({
            groupId: month,
            headerName: new Date(2000, month - 1, 1).toLocaleString("default", { month: "short" }).toUpperCase(),
            children: weeks.map((week) => ({ field: week })),
        }));

        // Add a full-year (FY) total column
        const fyColumn = {
            field: "fyTotal",
            headerName: "FY Total",
            type: "number",
            width: 120,
            valueGetter: (value, row ) =>
                row && row.units
                    ? Object.values(row.units).reduce((sum, { totalUnits }) => sum + totalUnits, 0)
                    : 0,
        };

        // Base columns (SKU Description and Week Columns)
        const baseColumns = [
            { field: "skuNumber", headerName: "SKU", width: 100 },
            { field: "skuDescription", headerName: "Description", width: 200 },
            ...weekColumns,
            fyColumn,
        ];

        // Rows for the DataGrid
        const rows = summaryData.periodicDemands.map((demand, index) => ({
            id: index, // DataGrid requires a unique ID
            skuNumber: demand.skuNumber || "Unknown SKU",
            skuDescription: demand.skuDescription || "Unknown SKU",
            units: demand.units || {}, // Ensure units is at least an empty object
        }));

        return {
            columns: baseColumns,
            columnGroupingModel,
            rows,
        };
    }, [summaryData]);

    // Custom toolbar
    const CustomToolbar = () => (
        <GridToolbarContainer>
            {/* Add any custom actions here */}
        </GridToolbarContainer>
    );

    // Render fallback message for no data
    if (rows.length === 0) {
        return <p>No data available</p>;
    }

    return (
        <Box sx={{ height: 600, width: "100%" }}>
            <DataGrid
                rows={rows}
                columns={columns}
                columnGroupingModel={columnGroupingModel}
                experimentalFeatures={{ columnGrouping: true }}
                pageSize={10}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
        </Box>
    );
};

OutputsDemandTable.propTypes = {
    summaryData: PropTypes.shape({
        periodicDemands: PropTypes.arrayOf(
            PropTypes.shape({
                skuNumber: PropTypes.string.isRequired,
                skuDescription: PropTypes.string.isRequired,
                units: PropTypes.objectOf(
                    PropTypes.shape({
                        totalUnits: PropTypes.number.isRequired,
                        weekMonth: PropTypes.number.isRequired,
                    })
                ).isRequired,
            })
        ).isRequired,
        weekToMonthMap: PropTypes.objectOf(PropTypes.number).isRequired, // Week to Month mapping
    }).isRequired,
};

export default OutputsDemandTable;
