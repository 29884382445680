import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axiosInstance from "../AxiosInstance";
import { Chip } from "@mui/material";

const ForecastProductMultiSelectDropdown = ({
                                                forecastId,
                                                customerId,
                                                forecastPeriodId,
                                                onProductsSelected,
                                                productErrorText,
                                                disableCloseOnSelect = false,
                                            }) => {
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [helperText, setHelperText] = useState("");

    useEffect(() => {
        if (forecastPeriodId !== null) {
            fetchProducts();
        }
    }, [forecastId, customerId, forecastPeriodId]);

    useEffect(() => {
        setHelperText(productErrorText);
    }, [productErrorText]);

    const fetchProducts = async () => {
        try {
            const url = `/forecasts/${forecastId}/products${
                customerId
                    ? `?customerId=${customerId}&forecastPeriodId=${forecastPeriodId}`
                    : `?forecastPeriodId=${forecastPeriodId}`
            }`;
            const response = await axiosInstance.get(url);
            setProducts(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleProductChange = (event, values) => {
        setSelectedProducts(values);
        const selectedProductDetails = values.map((value) => ({
            skuBaseForecastId: value.skuBaseForecastId,
            productId: value.productId,
        }));
        onProductsSelected(selectedProductDetails);
    };

    return (
        <div>
            <Autocomplete
                multiple
                id="forecast-product-dropdown"
                disableCloseOnSelect={disableCloseOnSelect}
                options={products}
                getOptionLabel={(product) => `${product.skuDescription} - ${product.skuNumber}`}
                value={selectedProducts}
                onChange={handleProductChange}
                filterSelectedOptions
                isOptionEqualToValue={(option, value) => option.skuNumber === value.skuNumber}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="SKU"
                        error={Boolean(helperText)}
                        helperText={helperText}
                    />
                )}
                renderTags={(value, getTagProps) => {
                    const numTags = value.length;
                    const limitTags = 1;

                    return (
                        <>
                            {value.slice(0, limitTags).map((option, index) => (
                                <Chip
                                    {...getTagProps({ index })}
                                    key={index}
                                    label={`${option.skuDescription} - ${option.skuNumber}`}
                                />
                            ))}

                            {numTags > limitTags && ` +${numTags - limitTags}`}
                        </>
                    );
                }}
            />
        </div>
    );
};

export default ForecastProductMultiSelectDropdown;
