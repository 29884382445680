import React, {useState} from 'react';
import {Container, TextField, Button, Typography, Paper, Snackbar, Alert} from '@mui/material';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSuccessMessage('');
        setErrorMessage('');

        try {
            const response = await fetch('/api/auth/forgot-password', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({email})
            });

            if (response.ok) {
                const result = await response.json();
                setSuccessMessage(result.message);
            } else {
                const result = await response.json();
                setErrorMessage(result.message || 'An error occurred. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('An unexpected error occurred. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Container component="main" maxWidth="xs" className="component-container">
            <Paper elevation={3}
                   style={{padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Typography component="h1" variant="h5">
                    Forgot Password
                </Typography>
                <Typography variant="body2" color="textSecondary" align="center" style={{marginTop: '8px'}}>
                    Enter your email address below, and we'll send you a link to reset your password.
                </Typography>
                <form onSubmit={handleSubmit} style={{width: '100%', marginTop: '16px'}}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        style={{margin: '24px 0 16px'}}
                    >
                        {isSubmitting ? 'Submitting...' : 'Send Reset Link'}
                    </Button>
                </form>
            </Paper>

            {/* Success Snackbar */}
            <Snackbar
                open={!!successMessage}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                autoHideDuration={6000}
                onClose={() => setSuccessMessage('')}
            >
                <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{width: '100%'}}>
                    {successMessage}
                </Alert>
            </Snackbar>

            {/* Error Snackbar */}
            <Snackbar
                open={!!errorMessage}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                autoHideDuration={6000}
                onClose={() => setErrorMessage('')}
            >
                <Alert onClose={() => setErrorMessage('')} severity="error" sx={{width: '100%'}}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default ForgotPassword;
