export const formatDate = (dateValue) => {
    if (!dateValue) return '';

    const date = new Date(dateValue);
    if (isNaN(date.getTime())) return '';

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
}

export const formatDateForInput = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

// Utility function to safely format dates as DD/MM/YYYY
export const formatDisplayDate = (isoDate) => {
    if (!isoDate) return ''; // Return an empty string for null or undefined dates
    const [year, month, day] = isoDate.split('-');
    return `${day}/${month}/${year}`; // Convert YYYY-MM-DD to DD/MM/YYYY
};

export function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(today.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export const parseDate = (dateStr) => {
    if (!dateStr) return null;
    const parts = dateStr.split('-');
    if (parts.length !== 3) return null;

    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Month is 0-indexed
    const year = parseInt(parts[2], 10);

    const date = new Date(year, month, day);
    return isNaN(date.getTime()) ? null : date;
};

/**
 * Converts a date string from "dd-MM-yyyy" format to a Date object.
 * @param {string} dateString - The date string in "dd-MM-yyyy" format.
 * @returns {Date} A Date object representing the given date, or null if the input is invalid.
 */
function convertDateStringToDate(dateString) {
    if (!dateString || !/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
        console.error("Invalid date string format. Expected format: dd-MM-yyyy");
        return null;
    }

    const parts = dateString.split('-').map(part => parseInt(part, 10));
    const year = parts[2];
    const month = parts[1] - 1; // Month is 0-indexed in JavaScript Date
    const day = parts[0];

    const date = new Date(year, month, day);
    if (isNaN(date.getTime())) {
        console.error("Invalid date components. Conversion resulted in an invalid date.");
        return null;
    }

    return date;
}

export default convertDateStringToDate;

