import React, {useEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import ForecastDropdown from "./ForecastDropdown";
import CustomerDropdown from "./CustomerDropdown";
import ForecastPeriodDropdown from "./ForecastPeriodDropdown";
import SkuBaseForecastTable from "./SkuBaseForecastTable";
import Box from "@mui/material/Box";
import PromotionsTable from "./PromotionsTable";
import {
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Snackbar, Skeleton
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {Addchart} from "@mui/icons-material";
import axiosInstance from "../AxiosInstance";
import './Styles.css';

const CustomerForecast = () => {
    const [forecasts, setForecasts] = useState([]);
    const [selectedForecastId, setSelectedForecastId] = useState(null);
    const [selectedForecastPeriodId, setSelectedForecastPeriodId] = useState(null);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [hasSkuBaseForecasts, setHasSkuBaseForecasts] = useState(false);
    const [refreshSkuBaseForecasts, setRefreshSkuBaseForecasts] = useState(false);
    const [promotionProductsData, setPromotionProductsData] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [currentForecastPeriodName, setCurrentForecastPeriodName] = useState("");
    const [newForecastPeriodName, setNewForecastPeriodName] = useState("");
    const [validationError, setValidationError] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("error");
    const [loadingForecasts, setLoadingForecasts] = useState(true);

    const location = useLocation();
    const isLocked = location.state?.isLocked || false;

    const getToken = () => localStorage.getItem("token");

    useEffect(() => {
        const storedForecast = JSON.parse(localStorage.getItem("selectedForecast"));
        const storedForecastPeriodId = localStorage.getItem("selectedForecastPeriodId");

        if (storedForecast?.forecastId) {
            setSelectedForecastId(storedForecast.forecastId);
        }

        if (storedForecastPeriodId) {
            setSelectedForecastPeriodId(storedForecastPeriodId);
        } else {
            setSelectedForecastPeriodId(null);
        }

        fetchForecastsAndPeriods();
    }, []);

    useEffect(() => {
        if (selectedForecastId && selectedForecastPeriodId) {
            const forecast = forecasts.find((f) => f.forecastId === selectedForecastId);
            const forecastPeriod = forecast?.forecastPeriods.find(
                (fp) => fp.forecastPeriodId === Number(selectedForecastPeriodId)
            );
            if (forecastPeriod) {
                setCurrentForecastPeriodName(forecastPeriod.name);
            } else {
                setCurrentForecastPeriodName("");
            }
        } else {
            setCurrentForecastPeriodName("");
        }
    }, [selectedForecastId, selectedForecastPeriodId, forecasts]);

    const fetchForecastsAndPeriods = async () => {
        try {
            setLoadingForecasts(true);
            const token = getToken();
            const response = await axiosInstance.get("/forecasts", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setForecasts(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingForecasts(false);
        }
    };

    const handleForecastSelected = (id) => {
        localStorage.setItem("selectedForecast", JSON.stringify({forecastId: id}));
        setSelectedForecastId(id);
        setSelectedForecastPeriodId(null);
        setCurrentForecastPeriodName("");
    };

    const handleForecastPeriodSelected = (forecastPeriodId) => {
        if (forecastPeriodId) {
            localStorage.setItem("selectedForecastPeriodId", forecastPeriodId);
            setSelectedForecastPeriodId(forecastPeriodId);
            const forecast = forecasts.find((f) => f.forecastId === selectedForecastId);
            const forecastPeriod = forecast?.forecastPeriods.find((fp) => fp.forecastPeriodId === forecastPeriodId);
            if (forecastPeriod) {
                setCurrentForecastPeriodName(forecastPeriod.name);
            }
        } else {
            localStorage.removeItem("selectedForecastPeriodId");
            setSelectedForecastPeriodId(null);
            setCurrentForecastPeriodName("");
        }
    };

    const handleCustomerSelected = (selection) => {
        if (typeof selection === "object" && selection !== null) {
            if (selection.type === "customer") {
                setSelectedCustomerId(selection.id);
            } else {
                setSelectedCustomerId(null);
            }
        } else {
            setSelectedCustomerId(selection);
        }
    };

    const handleForecastsChange = (forecasts) => {
        setHasSkuBaseForecasts(forecasts && forecasts.length > 0);
    };

    const handlePromotionProductsDataChange = (data) => {
        setPromotionProductsData(data);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        setValidationError("");
        setNewForecastPeriodName("");
    };

    const handleCreateForecastPeriod = async () => {
        const nameRegex = /^[a-zA-Z0-9\s]+$/;
        if (!nameRegex.test(newForecastPeriodName.trim())) {
            setValidationError("Please enter a valid name. Only alphanumeric characters and spaces are allowed.");
            return;
        }

        setIsDialogOpen(false);
        setValidationError("");
        try {
            await axiosInstance.post(`/forecasts/${selectedForecastId}/forecastperiods`, {
                name: newForecastPeriodName.trim(),
            });

            const token = getToken();
            const response = await axiosInstance.get("/forecasts", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setForecasts(response.data);

            const updatedForecast = response.data.find((f) => f.forecastId === selectedForecastId);
            if (updatedForecast) {
                const newForecastPeriod = updatedForecast.forecastPeriods.find(
                    (fp) => fp.name === newForecastPeriodName.trim()
                );
                if (newForecastPeriod) {
                    setSelectedForecastPeriodId(newForecastPeriod.forecastPeriodId);
                }
            }

            setSnackbarMessage("Forecast period created successfully!");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        } catch (error) {
            console.error("Failed to create forecast period:", error);

            setSnackbarMessage(error.response?.data || "An unexpected error occurred.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        } finally {
            setNewForecastPeriodName("");
        }
    };

    const openCreateForecastPeriodDialog = () => {
        const sortedForecastPeriods = selectedForecast
            ? [...selectedForecast.forecastPeriods].sort((a, b) => a.periodIndex - b.periodIndex)
            : [];
        const firstUnlockedPeriod = sortedForecastPeriods.find((fp) => !fp.isLocked);

        if (firstUnlockedPeriod) {
            setCurrentForecastPeriodName(firstUnlockedPeriod.name);
        }

        setIsDialogOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarMessage("");
    };

    const selectedForecast = useMemo(() => {
        return forecasts.find((f) => f.forecastId === selectedForecastId);
    }, [forecasts, selectedForecastId]);

    const LoadingWrapper = ({isLoading, children, skeletonHeight}) => {
        return isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={skeletonHeight || 600}/>
        ) : (
            children
        );
    };

    return (
        <Box className="component-container">
            <Box sx={{display: "flex", alignItems: "center", gap: 2, pb: 2}}>
                {loadingForecasts ? (
                    <Skeleton variant="rectangular" width={200} height={40}/>
                ) : (
                    <ForecastDropdown
                        forecasts={forecasts}
                        selectedForecastId={selectedForecastId}
                        onForecastSelected={handleForecastSelected}
                    />
                )}
                {loadingForecasts ? (
                    <Skeleton variant="rectangular" width={200} height={40}/>
                ) : (
                    <ForecastPeriodDropdown
                        forecastPeriods={selectedForecast ? selectedForecast.forecastPeriods : []}
                        selectedForecastPeriodId={selectedForecastPeriodId}
                        onForecastPeriodSelected={handleForecastPeriodSelected}
                    />
                )}
                {loadingForecasts ? (
                    <Skeleton variant="circular" width={32} height={32}/>
                ) : (
                    <IconButton
                        aria-label="createForecastPeriod"
                        onClick={openCreateForecastPeriodDialog}
                        disabled={!selectedForecastPeriodId}
                    >
                        <Addchart/>
                    </IconButton>
                )}
                {loadingForecasts ? (
                    <Skeleton variant="rectangular" width={200} height={40}/>
                ) : (
                    <CustomerDropdown onCustomerSelected={handleCustomerSelected}/>
                )}
            </Box>
            <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Create Forecast Period</DialogTitle>
                <DialogContent>
                    <p>
                        Are you sure you wish to create the next forecast period? The current forecast period{" "}
                        <strong>{currentForecastPeriodName}</strong> will be locked. This action cannot be undone.
                    </p>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Forecast Period Name"
                        fullWidth
                        value={newForecastPeriodName}
                        onChange={(e) => setNewForecastPeriodName(e.target.value)}
                        error={!!validationError}
                        helperText={validationError}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleCreateForecastPeriod} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            {selectedForecastId && selectedForecastPeriodId && selectedCustomerId && (
                <Box>
                    <Box sx={{display: "flex-end", gap: 2, pb: 2}}>
                        <SkuBaseForecastTable
                            forecastId={selectedForecastId}
                            forecastPeriodId={selectedForecastPeriodId}
                            customerId={selectedCustomerId}
                            isLocked={isLocked}
                            onForecastsChange={handleForecastsChange}
                            refresh={refreshSkuBaseForecasts}
                            onRefreshed={() => setRefreshSkuBaseForecasts(false)}
                            promotionProductsData={promotionProductsData}
                        />
                    </Box>
                    {hasSkuBaseForecasts && (
                        <Box sx={{display: "flex-end", gap: 2, pb: 2}}>
                            <PromotionsTable
                                forecastId={selectedForecastId}
                                forecastPeriodId={selectedForecastPeriodId}
                                customerId={selectedCustomerId}
                                onRefreshSkuBaseForecasts={() => setRefreshSkuBaseForecasts(true)}
                                onPromotionProductsDataChange={handlePromotionProductsDataChange}
                            />
                        </Box>
                    )}
                </Box>
            )}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            >
                <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{width: "100%"}}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Box>
    );
};

export default CustomerForecast;
