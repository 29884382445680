import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LockIcon from '@mui/icons-material/Lock';
import Box from '@mui/material/Box';

const ForecastPeriodDropdown = ({ forecastPeriods, selectedForecastPeriodId, onForecastPeriodSelected }) => {
    const sortedForecastPeriods = [...forecastPeriods].sort((a, b) => a.periodIndex - b.periodIndex);

    const selectedValue = sortedForecastPeriods.find(
        fp => fp.forecastPeriodId === Number(selectedForecastPeriodId)
    ) || null;

    const handleForecastPeriodChange = (event, value) => {
        // If cleared, set to null
        onForecastPeriodSelected(value ? value.forecastPeriodId : null);
    };

    return (
        <Autocomplete
            disablePortal
            id="combo-box-forecast-period"
            options={sortedForecastPeriods}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.forecastPeriodId === value.forecastPeriodId}
            sx={{ width: 300 }}
            value={selectedValue}
            onChange={handleForecastPeriodChange}
            renderOption={(props, option) => (
                <Box
                    component="li"
                    {...props}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <span>{option.name}</span>
                    {option.isLocked && (
                        <Box component="span" sx={{ marginLeft: 'auto' }}>
                            <LockIcon fontSize="small" color="action" />
                        </Box>
                    )}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Forecast Period"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {selectedValue?.isLocked && (
                                    <LockIcon
                                        fontSize="small"
                                        color="action"
                                        sx={{ marginRight: 1 }}
                                    />
                                )}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

export default ForecastPeriodDropdown;
