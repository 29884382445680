import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

function ForecastDropdown({ forecasts, selectedForecastId, onForecastSelected }) {
    const [selectedForecast, setSelectedForecast] = useState(null);

    useEffect(() => {
        if (selectedForecastId && forecasts.length > 0) {
            const selected = forecasts.find(f => f.forecastId === parseInt(selectedForecastId, 10));
            setSelectedForecast(selected || null);
        }
    }, [selectedForecastId, forecasts]);

    const handleForecastChange = (event, value) => {
        setSelectedForecast(value);
        if (value) {
            const { forecastId, startPeriod } = value;
            localStorage.setItem('selectedForecast', JSON.stringify({ forecastId, startPeriod }));
            onForecastSelected(forecastId, startPeriod);
        } else {
            localStorage.removeItem('selectedForecast');
            onForecastSelected(null, null);
        }
    };

    return (
        <Autocomplete
            disablePortal
            id="combo-box-forecast"
            options={forecasts}
            getOptionLabel={(forecast) => forecast.name}
            isOptionEqualToValue={(option, value) => option.forecastId === value.forecastId}
            sx={{ width: 300 }}
            value={selectedForecast}
            onChange={handleForecastChange}
            renderInput={(params) => <TextField {...params} label="Year" />}
        />
    );
}

export default ForecastDropdown;
