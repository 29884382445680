import React, {useState} from 'react';
import {useSearchParams, useNavigate} from 'react-router-dom';
import {Container, TextField, Button, Typography, Paper, Snackbar, Alert} from '@mui/material';

function ResetPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const email = searchParams.get('email');
    const token = searchParams.get('token');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setErrorMessage("Passwords do not match.");
            return;
        }

        try {
            const response = await fetch('/api/auth/reset-password', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({email, token, newPassword: password})
            });

            if (response.ok) {
                setSuccessMessage("Password reset successfully. You can now log in.");
                setTimeout(() => navigate('/login'), 2000); // Redirect to login after success
            } else {
                const result = await response.json();
                setErrorMessage(result.message || "Password reset failed.");
            }
        } catch (error) {
            setErrorMessage("An unexpected error occurred. Please try again.");
        }
    };

    return (
        <Container component="main" maxWidth="xs" className="component-container">
            <Paper elevation={3}
                   style={{padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Typography component="h1" variant="h5">
                    Reset Password
                </Typography>
                <form onSubmit={handleSubmit} style={{width: '100%', marginTop: '16px'}}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        type="password"
                        label="New Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        type="password"
                        label="Confirm New Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{margin: '24px 0 16px'}}
                    >
                        Reset Password
                    </Button>
                </form>
            </Paper>

            {/* Success Message */}
            <Snackbar
                open={!!successMessage}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                autoHideDuration={6000}
                onClose={() => setSuccessMessage('')}
            >
                <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{width: '100%'}}>
                    {successMessage}
                </Alert>
            </Snackbar>

            {/* Error Message */}
            <Snackbar
                open={!!errorMessage}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                autoHideDuration={6000}
                onClose={() => setErrorMessage('')}
            >
                <Alert onClose={() => setErrorMessage('')} severity="error" sx={{width: '100%'}}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default ResetPassword;
