import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axiosInstance from "../AxiosInstance";

const ForecastProductDropdown = ({forecastId, customerId, forecastPeriodId, onProductSelected, productErrorText}) => {
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [helperText, setHelperText] = useState('');

    useEffect(() => {
        if (forecastId !== null && forecastPeriodId !== null) {
            fetchProducts();
        }
    }, [forecastId, customerId, forecastPeriodId]);

    useEffect(() => {
        setHelperText(productErrorText);
    }, [productErrorText]);

    const fetchProducts = async () => {
        try {
            const url = `/forecasts/${forecastId}/products${customerId ? `?customerId=${customerId}&forecastPeriodId=${forecastPeriodId}` : `?forecastPeriodId=${forecastPeriodId}`}`;
            const response = await axiosInstance.get(url);
            setProducts(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleProductChange = (event, value) => {
        setSelectedProduct(value);
        if (value) {
            onProductSelected({
                skuBaseForecastId: value.skuBaseForecastId,
                productId: value.productId
            });
        } else {
            onProductSelected({ skuBaseForecastId: null, productId: null });
        }
    };


    return (
        <div>
            <Autocomplete
                disablePortal
                id="forecast-product-dropdown"
                options={products}
                getOptionLabel={(product) => `${product.skuDescription} - ${product.skuNumber}`}
                sx={{width: 280}}
                value={selectedProduct}
                onChange={handleProductChange}
                isOptionEqualToValue={(option, value) => option.skuNumber === value.skuNumber}
                renderInput={(params) => (
                    <TextField {...params} label="SKU"
                               error={Boolean(helperText)}
                               helperText={helperText}/>
                )}
            />
        </div>
    );
};

export default ForecastProductDropdown;
