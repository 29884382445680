export const isMoneyGreaterThanZero = (value) => {
    const regex = /^(?:0|\d+)?(?:\.\d{1,2})?$/;
    return regex.test(value) && parseFloat(value) > 0;
};

export const isMoneyGreaterThanZeroThreeDecimals = (value) => {
    const regex = /^(?:0|\d+)?(?:\.\d{1,3})?$/;
    return regex.test(value) && parseFloat(value) > 0;
};

export const isMoneyGreaterOrEqualToZero = (value) => {
    const regex = /^(?:0|\d+)?(?:\.\d{1,2})?$/;
    return regex.test(value) && parseFloat(value) >= 0;
};

export function validateEndDate(startDate, endDate) {
    if (endDate === null || endDate === undefined || endDate === "") return true;

    if (!startDate) return false;

    const start = new Date(startDate);
    const end = new Date(endDate);

    const oneWeekInMillis = 7 * 24 * 60 * 60 * 1000;
    const timeDifference = end.getTime() - start.getTime();

    return timeDifference > oneWeekInMillis && end > new Date();
}

export function validateStartDate(startDate) {
    if (!startDate) {
        return false;
    }

    const date = new Date(startDate);

    if (!(date instanceof Date) || isNaN(date)) {
        return false;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return date >= today;
}

export function validateString(input) {
    if (typeof input !== 'string') {
        return false; // Not a string
    }

    const trimmedInput = input.trim();

    return trimmedInput.length !== 0;
}

export const isGreaterThanZero = (value) => {
    return Number.isInteger(value) && value > 0;
};

export const isGreaterOrEqualToZero = (value) => {
    const intValue = parseInt(value, 10);

    return !isNaN(intValue) && intValue >= 0 && Number.isInteger(intValue);
};

export const isWholeNumber = (distribution) => {
    const floatValue = parseFloat(distribution);
    return !isNaN(floatValue) && floatValue >= 0 && Number.isInteger(floatValue);
};
