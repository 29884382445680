import React, { useState, useEffect } from 'react';
import ForecastDropdown from "./ForecastDropdown";
import ForecastPeriodDropdown from "./ForecastPeriodDropdown";
import Box from "@mui/material/Box";
import axiosInstance from "../AxiosInstance";
import OutputsDemandTable from "./OutputsDemandTable";
import {Skeleton} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {downloadCsv} from "../Utilities/csvUtility";

const ForecastOutputsDemand = () => {
    const [forecasts, setForecasts] = useState([]);
    const [selectedForecastId, setSelectedForecastId] = useState(null);
    const [selectedForecastPeriodId, setSelectedForecastPeriodId] = useState(null);
    const [startPeriod, setStartPeriod] = useState(1);
    const [summaryData, setSummaryData] = useState({
        periodicDemands: [],
        weekToMonthMap: {},
    });
    const [loadingForecasts, setLoadingForecasts] = useState(true);
    const [loadingTable, setLoadingTable] = useState(false);
    const [error, setError] = useState(null);
    const [loadingCsv, setLoadingCsv] = useState(false);

    const getToken = () => localStorage.getItem("token");

    useEffect(() => {
        const storedForecast = JSON.parse(localStorage.getItem('selectedForecast'));
        const storedForecastPeriodId = localStorage.getItem('selectedForecastPeriodId');

        if (storedForecast?.forecastId) {
            setSelectedForecastId(storedForecast.forecastId);
            setStartPeriod(storedForecast.startPeriod);
        }

        if (storedForecastPeriodId && storedForecastPeriodId !== "null") {
            setSelectedForecastPeriodId(parseInt(storedForecastPeriodId, 10));
        } else {
            setSelectedForecastPeriodId(null);
        }

        fetchForecastsAndPeriods();
    }, []);

    const fetchForecastsAndPeriods = async () => {
        try {
            setLoadingForecasts(true);
            const token = getToken();
            const response = await axiosInstance.get("/forecasts", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setForecasts(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingForecasts(false);
        }
    };

    useEffect(() => {
        if (selectedForecastId && selectedForecastPeriodId !== null) {
            const fetchAccountSummary = async () => {
                setLoadingTable(true);
                try {
                    const params = { forecastPeriodId: selectedForecastPeriodId };
                    const response = await axiosInstance.get(`/forecasts/${selectedForecastId}/outputs-demand`, { params });
                    setSummaryData(response.data);
                } catch (error) {
                    console.error("Failed to fetch output demand:", error);
                    setSummaryData({ periodicDemands: [],
                        weekToMonthMap: {}, });
                } finally {
                    setLoadingTable(false);
                }
            };

            fetchAccountSummary();
        }
    }, [selectedForecastId, selectedForecastPeriodId]);

    const selectedForecast = forecasts.find((f) => f.forecastId === selectedForecastId);

    const handleDownloadCsv = async () => {
        try {
            const params = { forecastPeriodId: selectedForecastPeriodId };
            const fileName = `OutputsDemand_${selectedForecastId}_${selectedForecastPeriodId}.csv`;

            await downloadCsv(`/forecasts/${selectedForecastId}/outputs-demand-csv`, params, fileName, setLoadingCsv, setError);
        } catch (error) {
            console.error("Unexpected error during CSV download:", error);
        }
    };

    return (
        <Box className="component-container">
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, pb: 2 }}>
                {loadingForecasts ? (
                    <Skeleton variant="rectangular" width={200} height={40} />
                ) : (
                    <ForecastDropdown
                        forecasts={forecasts}
                        selectedForecastId={selectedForecastId}
                        onForecastSelected={setSelectedForecastId}
                    />
                )}
                {loadingForecasts ? (
                    <Skeleton variant="rectangular" width={200} height={40} />
                ) : (
                    <ForecastPeriodDropdown
                        forecastPeriods={selectedForecast?.forecastPeriods || []}
                        selectedForecastPeriodId={selectedForecastPeriodId}
                        onForecastPeriodSelected={setSelectedForecastPeriodId}
                    />
                )}
                {loadingForecasts ? (
                    <Skeleton variant="circular" width={32} height={32} />
                ) : (
                    <Tooltip title="Download CSV">
                    <span>
                        <IconButton onClick={handleDownloadCsv} aria-label="download csv" disabled={loadingCsv}>
                            {loadingCsv ? <CircularProgress size={24} /> : <FileDownloadIcon />}
                        </IconButton>
                    </span>
                    </Tooltip>
                )}
            </Box>
            {loadingTable ? (
                <Skeleton variant="rectangular" width="100%" height={600} />
            ) : (
                selectedForecastId &&
                selectedForecastPeriodId !== null && (
                    <OutputsDemandTable summaryData={summaryData} viewByMonth={false} />
                )
            )}
        </Box>
    );
};

export default ForecastOutputsDemand;
