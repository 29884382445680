import React, {useState} from 'react';
import {Container, TextField, Button, Snackbar, Alert, Typography, Paper} from '@mui/material';
import axiosInstance from '../AxiosInstance';
import Box from "@mui/material/Box";

function ProfilePage() {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmNewPassword) {
            setErrorMessage("New passwords do not match.");
            setErrorSnackbarOpen(true);
            return;
        }

        try {
            const response = await axiosInstance.post('/auth/change-password', {
                oldPassword,
                newPassword
            });

            if (response.status === 200) {
                setSuccessSnackbarOpen(true);
                setOldPassword('');
                setNewPassword('');
                setConfirmNewPassword('');
            }
        } catch (error) {
            setErrorMessage(error.response?.data?.message || "Failed to change password.");
            setErrorSnackbarOpen(true);
        }
    };

    return (
        <Box className="component-container">
            <Container component="main" maxWidth="xs">
                <Paper elevation={3}
                       style={{padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Typography component="h1" variant="h5">Change Password</Typography>
                    <form onSubmit={handleSubmit} style={{width: '100%', marginTop: '8px'}}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Old Password"
                            type="password"
                            value={oldPassword}
                            onChange={e => setOldPassword(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="New Password"
                            type="password"
                            value={newPassword}
                            onChange={e => setNewPassword(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Confirm New Password"
                            type="password"
                            value={confirmNewPassword}
                            onChange={e => setConfirmNewPassword(e.target.value)}
                            inputProps={{
                                onPaste: (e) => e.preventDefault(),
                            }}
                        />
                        <Button type="submit" fullWidth variant="contained" color="primary" style={{marginTop: '24px'}}>
                            Change Password
                        </Button>
                    </form>
                </Paper>
                <Snackbar open={errorSnackbarOpen} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                          autoHideDuration={6000} onClose={() => setErrorSnackbarOpen(false)}>
                    <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error" sx={{width: '100%'}}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
                <Snackbar open={successSnackbarOpen} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                          autoHideDuration={6000} onClose={() => setSuccessSnackbarOpen(false)}>
                    <Alert onClose={() => setSuccessSnackbarOpen(false)} severity="success" sx={{width: '100%'}}>
                        Password changed successfully.
                    </Alert>
                </Snackbar>
            </Container>
        </Box>
    );
}

export default ProfilePage;
